import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import FastfoodIcon from '@material-ui/icons/Phone'
// import RepeatIcon from '@material-ui/icons/Repeat'
import RegistrationIcon from '@material-ui/icons/Create'
import AppointmentIcon from '@material-ui/icons/EventAvailable'
import ClearIcon from '@material-ui/icons/Clear'
import VideocamIcon from '@material-ui/icons/Videocam'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import HomeIcon from '@material-ui/icons/Home'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import HealingIcon from '@material-ui/icons/Healing'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '60px',
    fontFamily: 'Roboto',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export default function CustomizedTimeline() {
  const classes = useStyles()

  return (
    <section id="blogs" className="major" style={{ backgroundColor: '#fff' }}>
      <div className="inner">
        <div className="content">
          <section id="subscribe">
            <div className="inner">
              <section>
                <div className="box alt">
                  <div className="grid-wrapper">
                    <div className="col-12">
                      <Timeline align="alternate" className={classes.root}>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              9:30 am
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <FastfoodIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Call/whatsapp 7356714004
                              </Typography>
                              <Typography>Discuss your problem</Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              09:35 am
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <RegistrationIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Registration
                              </Typography>
                              <Typography>
                                We will ask your contact details
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              09:36 am
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            {/* <TimelineDot color="primary" variant="outlined"> */}
                            <TimelineDot color="primary">
                              <AppointmentIcon />
                            </TimelineDot>
                            <TimelineConnector
                              className={classes.secondaryTail}
                            />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Appointment
                              </Typography>
                              <Typography>
                                We will fix consultation time with our doctor
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              12:30 pm
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <VideocamIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Consultation
                              </Typography>
                              <Typography>
                                Video conferencing with zoom
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              1:30 pm
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <LocalHospitalIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Prescribe medication
                              </Typography>
                              <Typography>Based on condition</Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              Same day
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <MonetizationOnIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Payment
                              </Typography>
                              <Typography>
                                G Pay at the comfort of your home
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              01:45 am
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <CheckBoxIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Medicine Dispensing
                              </Typography>
                              <Typography>
                                From an approved pharmacy/clinic
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              Max 3 days
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <HomeIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Home Delivery
                              </Typography>
                              <Typography>By Courier/carrier</Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              2 weeks
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <HealingIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Healing Time
                              </Typography>
                              <Typography>A holistic approach</Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              3rd week
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary">
                              <EmojiEmotionsIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Receive Call
                              </Typography>
                              <Typography>
                                Your consultant will ring you
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                        {/* <TimelineItem></TimelineItem> */}
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                              Never
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="secondary">
                              {/* <RepeatIcon /> */}
                              <ClearIcon />
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h6" component="h1">
                                Stop the Chain
                              </Typography>
                              <Typography>
                                Our belief! Our moto is not to have repeat
                                patients
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}
