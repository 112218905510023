import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import BannerGeneric from '../components/BannerGeneric'
import Layout from '../components/layout'
import { Seo } from '../components/Seo'
import Workflow from '../components/Workflow'
import { IBlogPost } from '../utils/types'

// interface BlogsProps {
//   data: {
//     allContentfulBlogPost: {
//       nodes: IBlogPost[]
//     }
//   }
// }
const Process: React.FC<{}> = () => {
  return (
    <>
      <Layout>
        <Seo title="bloomB Process" />
        <BannerGeneric
          title="Our Process"
          description="A well defined structure suiting your need"
        >
          <ul className="actions">
            <li>
              <Link to={`/`} className="button special">
                Back
              </Link>
            </li>
          </ul>
        </BannerGeneric>
        <Workflow />
      </Layout>
    </>
  )
}

export default Process

// const blogs2Query = graphql`
//   query Blogs2Query {
//     allContentfulBlogPost {
//       nodes {
//         id
//         slug
//         title
//         description {
//           childMarkdownRemark {
//             excerpt(pruneLength: 75)
//           }
//         }
//         heroImage {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 900
//                 height: 600
//                 placeholder: BLURRED
//                 formats: [AUTO, WEBP, AVIF]
//               )
//             }
//           }
//         }
//         heroImage {
//           file {
//             url
//           }
//         }
//         author {
//           name
//           slug
//           image {
//             localFile {
//               childImageSharp {
//                 gatsbyImageData(
//                   width: 65
//                   height: 65
//                   placeholder: BLURRED
//                   formats: [AUTO, WEBP, AVIF]
//                 )
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

// export default () => (
//   <StaticQuery query={blogs2Query} render={data => <Journals data={data} />} />
// )
